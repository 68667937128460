import React from 'react'
import { graphql } from 'gatsby'
import ProductPage from '../components/product-page'

const ContentfulProductPage = ({ data, location }) => (
  <ProductPage {...data.contentfulProductPage} location={location} />
)

export const pageQuery = graphql`
  query ProductPageQuery($id: String) {
    contentfulProductPage(id: { eq: $id }) {
      id
      product {
        ...Product
      }
      selectedFeatures {
        ...ProductFeatureType
      }
      faq {
        ...Faq
      }
      backLink {
        ...Link
      }
      productLinkText
      mobileBackgroundBynderImage {
        original
      }
      backgroundBynderImage {
        original
      }
      featuresTitle
      termsTitle
      subnavigation {
        ...Subnavigation
      }
      footer {
        ...Footer
      }
      seoMetadata {
        ...SeoMetadata
      }
      stickyBarControl
      stickyBarText
    }
  }
`

export default ContentfulProductPage
